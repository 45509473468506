import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectAccessToken,
  selectBuildingFilter,
  selectCompanyAndSiteSettings,
  selectDepartmentsFilter,
  selectFloorsFilter
} from "core/selectors";

import { useAttendanceQuery } from "features/attendance/attendanceApiSlice";

import { calculateTotalArrivals, fillEmployeesSchedules } from "pages/Attendance/helpers";

import { PICKET_DEFAULT } from "shared/components/MonthPicker/MonthPicker";
import { WORK_DAYS_COUNT } from "shared/constants";
import { isEqualData } from "shared/lib";

import { getCountWeeksInMonth, getFromToDates, getFromToDatesByWeeks } from "../utils/previousWeeksGetters";
import previousWeeksTableColumns from "../utils/previousWeeksTableColumns";

export const NUMBER_OF_WEEKS = 6;
const { fromDate: FROM_DATE, toDate: TO_DATE } = getFromToDatesByWeeks(NUMBER_OF_WEEKS);

const mapStateToProps = state => ({
  token: selectAccessToken(state),
  companyAndSiteSettings: selectCompanyAndSiteSettings(state),
  building: selectBuildingFilter(state),
  floors: selectFloorsFilter(state),
  departments: selectDepartmentsFilter(state)
});

export default function usePreviousWeeksTabLogic({ setSummary }) {
  /* ------------------ STATEs ------------------ */

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(previousWeeksTableColumns(null, NUMBER_OF_WEEKS));
  const [weeksCount, setWeeksCount] = useState(NUMBER_OF_WEEKS);
  const [monthPickerValue, setMonthPickerValue] = useState(PICKET_DEFAULT);
  const [fromDate, setFromDate] = useState(FROM_DATE);
  const [toDate, setToDate] = useState(TO_DATE);
  const [avgEmployeeDays, setAvgEmployeeDays] = useState(0);
  const [avgEmployeeArrivals, setAvgEmployeeArrivals] = useState(0);
  const [avgEmployees, setAvgEmployees] = useState(0);

  const workdaysCountRef = useRef(0);

  const { token, companyAndSiteSettings, building, floors, departments } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: attendanceData, isLoading } = useAttendanceQuery(
    {
      fromDate,
      toDate,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  /* ------------------ HANDLERs ------------------ */

  const setOfficeDaysColumnHeaders = (data, weeksCount) => {
    if (!data) return;

    const item = data.find(item => {
      const countWeeksLen = (weeksCount + 1) * WORK_DAYS_COUNT;

      return item.schedule?.length === countWeeksLen;
    });
    const headItem = item || data[0];

    setColumns(previousWeeksTableColumns(headItem, weeksCount));
  };

  const getAttendanceHandler = () => {
    setAvgEmployeeDays(parseFloat(attendanceData?.avgEmployeeDaysPerWeek).toFixed(1));
    setAvgEmployeeArrivals(parseInt(attendanceData?.avgEmployeeArrivals));
    setAvgEmployees(parseInt(attendanceData?.avgEmployeesPerDay));

    const data = fillEmployeesSchedules(attendanceData.data, {
      startDate: fromDate,
      endDate: toDate,
      sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
      addWeekends: false,
      monthNumber: monthPickerValue.month
    });

    setRows(data);
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    setSummary &&
      setSummary({
        days: workdaysCountRef?.current,
        users: rows?.length
      });
  }, [rows, workdaysCountRef]);

  useEffect(() => {
    if (!attendanceData || isLoading) return;

    getAttendanceHandler();
    setOfficeDaysColumnHeaders(attendanceData.data, weeksCount);
  }, [attendanceData, isLoading, weeksCount]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSelectMonth = value => {
    if (value === NUMBER_OF_WEEKS) {
      setWeeksCount(NUMBER_OF_WEEKS);
      setMonthPickerValue(PICKET_DEFAULT);
      setFromDate(FROM_DATE);
      setToDate(TO_DATE);
    } else {
      const count = getCountWeeksInMonth(value);
      const { fromDate, toDate } = getFromToDates(value);

      setFromDate(fromDate);
      setToDate(toDate);
      setMonthPickerValue(value);
      setWeeksCount(count - 1);
    }
  };

  const onRenderFooterComponent = () => {
    const arrivals = calculateTotalArrivals(rows, {
      startDate: fromDate,
      endDate: toDate,
      sundayFirst: true,
      addWeekends: false,
      monthNumber: !isEqualData(monthPickerValue, PICKET_DEFAULT) ? monthPickerValue.month : undefined
    });

    workdaysCountRef.current = arrivals?.filter(day => day.activeDate)?.length;

    return arrivals;
  };

  return {
    rows,
    columns,
    monthPickerValue,
    fromDate,
    toDate,
    isLoading,
    onSelectMonth,
    onRenderFooterComponent,
    avgEmployeeDays,
    avgEmployeeArrivals,
    avgEmployees
  };
}
