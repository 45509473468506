import { Box, CircularProgress } from "@mui/material";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SvgLoader, SvgProxy } from "react-svgmt";

import { selectAccessToken, selectBuildingFilter, selectSiteFilter, selectUserManagementList } from "core/selectors";

import {
  useGetMapViewWorkstationsQuery,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { setWorkstationsByBuildings } from "features/workstations/workstationsSlice";

import WorkstationInfoPage from "pages/WorkstationInfoPage/WorkstationInfoPage";
import WorkstationPage from "pages/WorkstationPage/WorkstationPage";

import MainStage from "shared/components/LiveMap/MainStage";
import { isEqualData } from "shared/lib";
import {
  getAxisPositions,
  getEmptyWorkstations,
  getFormattedDate,
  getMappedWorkstationList,
  getPathAxisPositions,
  getWorkstationMapItemSvgProps
} from "shared/lib/getters";
import { isValidArray } from "shared/lib/validation";

const LiveViewWorkstationMap = ({ buildingPlan, selectedBuildingFloor, containerWidth }) => {
  const [workstationsMapList, setWorkstationMapList] = useState([]);
  const [notAssignedRoomsList, setNotAssignedRoomsList] = useState([]);
  const [svgRectElements, setSvgRectElements] = useState([]);
  const [svgPathElements, setSvgPathElement] = useState([]);
  const [rectMapList, setRectMapList] = useState([]);
  const [pathMapList, setPathMapList] = useState([]);
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [roomName, setRoomName] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [workstationId, setWorkstationId] = useState(null);
  const [workstationsList, setWorkstationsList] = useState([]);
  const [mapWorkstationList, setMapWorkstationList] = useState(null);

  const history = useHistory();
  const token = useSelector(selectAccessToken);
  const site = useSelector(selectSiteFilter);
  const building = useSelector(selectBuildingFilter);
  const userList = useSelector(selectUserManagementList);

  const dispatch = useDispatch();

  const { data: mapViewData, isLoading } = useGetMapViewWorkstationsQuery(
    { site, building, floor: selectedBuildingFloor },
    { skip: !site || !selectedBuildingFloor }
  );
  const { data: workstationData, isLoading: isWorkstationLoading } = useWorkstationsByBuildingsQuery(
    { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
    { skip: !token || !site || !building }
  );

  const onRectElementSelected = data => {
    setSvgRectElements(prevData => {
      if (!isEqualData(prevData, data)) {
        return data;
      } else {
        return prevData;
      }
    });
  };

  const onPathElementSelected = data => {
    setSvgPathElement(prevData => {
      if (!isEqualData(prevData, data)) {
        return data;
      } else {
        return prevData;
      }
    });
  };

  useEffect(() => {
    if (workstationData && !isWorkstationLoading) {
      const mappedList = getMappedWorkstationList(workstationData[building], userList);
      setWorkstationsList(mappedList);
      dispatch(setWorkstationsByBuildings(mappedList));
    }
  }, [workstationData, isWorkstationLoading]);

  useEffect(() => {
    if (isLoading || !mapViewData?.rooms) return;

    if (!workstationsList?.length) {
      setMapWorkstationList(mapViewData);
    } else {
      const rooms = { ...mapViewData.rooms };

      for (const key in mapViewData.rooms) {
        const room = mapViewData.rooms[key];

        const updatedRoom = room?.map(seat => {
          const { permanentOwner, assignedEmployee } = workstationsList.find(
            i => seat.workstationId === i.workstationId
          );

          return {
            ...seat,
            permanentOwner,
            assignedEmployee
          };
        });

        rooms[key] = updatedRoom;
      }

      setMapWorkstationList({ rooms });
    }
  }, [mapViewData, isLoading, workstationsList]);

  useEffect(() => {
    if (isValidArray(svgRectElements) && svgRectElements.length && mapWorkstationList) {
      const empty = getEmptyWorkstations(svgRectElements, mapWorkstationList);

      const workstationsList = svgRectElements?.flatMap(element => {
        const x = element?.x?.baseVal?.valueAsString;
        const y = element?.y?.baseVal?.valueAsString;
        const transform = element.attributes?.transform?.value;
        const height = element?.height?.baseVal?.valueAsString;
        const width = element?.width?.baseVal?.valueAsString;
        const roomName = element.attributes?.room_name?.value;

        const workstations =
          Object.hasOwn(mapWorkstationList?.rooms, parseInt(roomName)) && mapWorkstationList?.rooms[parseInt(roomName)];

        if (workstations?.length) {
          const res = workstations.map((workstation, index) => {
            const { xCoord, yCoord } = getAxisPositions({
              x: parseInt(x),
              y: parseInt(y),
              transform,
              height: parseInt(height),
              width: parseInt(width),
              index: index + 1
            });

            return {
              x: xCoord,
              y: yCoord,
              id: workstation?.workstationId,
              name: workstation?.workstationName,
              status: workstation?.status,
              owner: workstation?.permanentOwner,
              assigned: workstation?.assignedEmployee,
              ...getWorkstationMapItemSvgProps(workstation.status)
            };
          });
          return res;
        } else {
          return [];
        }
      });
      setRectMapList(workstationsList);
      setNotAssignedRoomsList(empty);
    }
  }, [svgRectElements, mapWorkstationList]);

  useEffect(() => {
    if (isValidArray(svgPathElements) && svgPathElements.length && mapWorkstationList) {
      const workstationsList = svgPathElements?.flatMap(element => {
        const roomName = element.attributes?.room_name?.value;
        const d = element.attributes?.d.nodeValue;

        const workstations =
          Object.hasOwn(mapWorkstationList?.rooms, parseInt(roomName)) && mapWorkstationList?.rooms[parseInt(roomName)];
        if (workstations?.length) {
          const res = workstations.map((workstation, index) => {
            const { xCoord, yCoord } = getPathAxisPositions({ d, index: index + 1 });

            return {
              x: xCoord,
              y: yCoord,
              id: workstation?.workstationId,
              name: workstation?.workstationName,
              status: workstation?.status,
              owner: workstation?.permanentOwner,
              assigned: workstation?.assignedEmployee,
              ...getWorkstationMapItemSvgProps(workstation.status)
            };
          });
          return res;
        } else {
          return [];
        }
      });

      setPathMapList(workstationsList);
    }
  }, [svgPathElements, mapWorkstationList]);

  useEffect(() => {
    if (pathMapList?.length || rectMapList?.length) {
      const payload = [...pathMapList, ...rectMapList];
      setWorkstationMapList(payload);
    }
  }, [pathMapList, rectMapList]);

  const onEmptyRoomClick = room => {
    setRoomName(room);
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onWorkstationClick = id => {
    setWorkstationId(id);
    setShowInfoSidebar(true);
  };

  return (
    <Box width="100%">
      <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
        <Box pt={2}>
          {!isLoading && buildingPlan ? (
            <Container px={1} position="relative" overflow="hidden">
              <Box display="flex" justifyContent="center">
                <MainStage
                  workstationsMapList={workstationsMapList}
                  notAssignedRoomsList={notAssignedRoomsList}
                  onEmptyRoomClick={onEmptyRoomClick}
                  onWorkstationClick={onWorkstationClick}
                  buildingPlan={buildingPlan?.plan}
                  xCoord={-50}
                  yCoord={-40}
                  containerWidth={containerWidth}
                />

                <Box display="none" width="100%">
                  <SvgLoader id="svgPlan" path={buildingPlan?.plan} style={{ width: "100%", height: "auto" }}>
                    <SvgProxy selector="rect" onElementSelected={onRectElementSelected} />
                    <SvgProxy selector="path" onElementSelected={onPathElementSelected} />
                  </SvgLoader>
                  <Box
                    position={"absolute"}
                    sx={{ width: "15px", height: "15px", borderRadius: "50%", background: "blue" }}
                  />
                </Box>
              </Box>
            </Container>
          ) : (
            <Box height="calc(100vh - 270px)" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      {showAddSidebar && (
        <WorkstationPage
          history={history}
          isOpen={showAddSidebar}
          setIsOpen={setShowAddSidebar}
          isEdit={isEdit}
          roomName={roomName}
        />
      )}
      {showInfoSidebar && (
        <WorkstationInfoPage
          history={history}
          isOpen={showInfoSidebar}
          setIsOpen={setShowInfoSidebar}
          workstationId={workstationId}
          isMapView={true}
        />
      )}
    </Box>
  );
};

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxHeight: "calc(100vh - 420px)"
  },

  [theme.breakpoints.up("xl")]: {
    maxHeight: "calc(100vh - 540px)"
  },

  [theme.breakpoints.up("xxl")]: {
    maxHeight: "calc(100vh - 620px)"
  },

  [theme.breakpoints.up("xxxl")]: {
    maxHeight: "calc(100vh - 860px)"
  }
}));

LiveViewWorkstationMap.propTypes = {
  path: PropTypes.string,
  selectedBuildingFloor: PropTypes.string,
  buildingPlan: PropTypes.object,
  containerWidth: PropTypes.number
};

export default React.memo(LiveViewWorkstationMap);
