import { apiSlice } from "core/api/apiSlice";

import { EEmployeeSettingName, ENonWorkingReason } from "shared/constants/settingConstants";

// Define types for API responses and requests
interface User {
  id: string;
  name: string;
  email: string;
  // Add other user properties as needed
}

interface AddUserRequest {
  name: string;
  email: string;
  // Add other fields required for adding a user
}

interface UpdateUserRequest {
  id: string;
  params: Partial<User>; // Assuming you can update any subset of user fields
}

interface UploadUserAvatarRequest {
  id: string;
  params: FormData; // Assuming avatar upload uses FormData
}

interface DeleteUserRequest {
  id: string;
}

interface EmployeesSettingsRequest {
  settingName: EEmployeeSettingName;
  siteId: string;
}

interface GetEmployeeSettingsRequest {
  cToken: string;
  employeeId: string;
}

interface SetOrUpdateEmployeeSettingRequest {
  cToken: string;
  employeeId: string;
  settingName: EEmployeeSettingName;
  settingValue: ENonWorkingReason;
}

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    users: build.query<User[], void>({
      query: () => ({
        url: "/employees/users",
        method: "GET"
      }),
      providesTags: ["User"]
    }),
    usersByIds: build.mutation<User[], { ids: string[]; cToken: string }>({
      query: ({ ids, cToken }) => ({
        url: "/employees/allByIds",
        method: "GET",
        params: {
          ids,
          cToken
        }
      })
    }),
    addUser: build.mutation<User, AddUserRequest>({
      query: args => ({
        url: "/employees/users/v1",
        method: "POST",
        body: args
      }),
      invalidatesTags: ["User"]
    }),
    updateUser: build.mutation<User, UpdateUserRequest>({
      query: ({ id, params }) => ({
        url: `/employees/users/${id}`,
        method: "PUT",
        body: params
      }),
      invalidatesTags: ["User"]
    }),
    uploadUserAvatar: build.mutation<void, UploadUserAvatarRequest>({
      query: ({ id, params }) => ({
        url: `/employees/users/${id}/image`,
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["User"]
    }),
    deleteUser: build.mutation<void, DeleteUserRequest>({
      query: ({ id }) => ({
        url: `/employees/users/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["User"]
    }),
    getDepartmentsList: build.query<any, void>({
      // Replace 'any' with the actual type if known
      // TODO: deprecated // use departments from AdminSlice
      query: () => ({
        url: "/employees/departments",
        method: "GET"
      })
    }),
    employeesSettings: build.query<any, EmployeesSettingsRequest>({
      // Replace 'any' with the actual type if known
      query: ({ settingName, siteId }) => ({
        url: `/employees/employeeSettings/${settingName}`,
        method: "GET",
        params: { siteId }
      })
    }),
    getEmployeeSettings: build.query<any, GetEmployeeSettingsRequest>({
      // Replace 'any' with the actual type if known
      query: ({ cToken, employeeId }) => ({
        url: `/employees/getEmployeeSettings`,
        method: "GET",
        params: { cToken, employeeId },
        keepUnusedDataFor: 0.0001
      }),
      providesTags: ["EmployeeSettings"]
    }),
    setOrUpdateEmployeeSetting: build.mutation<void, SetOrUpdateEmployeeSettingRequest>({
      query: ({ cToken, employeeId, settingName, settingValue }) => ({
        url: `/employees/setOrUpdateEmployeeSetting`,
        method: "PUT",
        params: {
          cToken,
          employeeId,
          settingName,
          settingValue
        }
      }),
      invalidatesTags: ["EmployeeSettings"]
    })
  })
});

export const {
  useUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetDepartmentsListQuery,
  useDeleteUserMutation,
  useUsersByIdsMutation,
  useUploadUserAvatarMutation,
  useSetOrUpdateEmployeeSettingMutation,
  useGetEmployeeSettingsQuery,
  useEmployeesSettingsQuery
} = userApiSlice;
