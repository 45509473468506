// @ts-nocheck
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import { isEqual } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { useDeepCompareEffect } from "use-deep-compare";

import {
  selectAccessToken,
  selectBuildingFilter,
  selectCompanyName,
  selectCompanySite,
  selectFloorsFilter,
  selectSiteFilter,
  selectUserManagementList
} from "core/selectors";

import {
  useGetMapViewWorkstationsQuery,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";
import { setWorkstationsByBuildings } from "features/workstations/workstationsSlice";

import FilterWorkstationSidebar from "shared/components/DataTable/components/FilterWorkstationSidebar/FilterWorkstationSidebar";
import SiteAndBuildingPage from "shared/components/DataTable/components/SiteAndBuildingSidebar/SiteAndBuildingSidebar";
import FloorSelector from "shared/components/Filters/Floor/Floor";
import SiteAndBuilding from "shared/components/Filters/SiteAndBulding/SiteAndBulding";
import MainStage from "shared/components/LiveMap/MainStage";
import WorkstationInfo from "shared/components/WorkstationInfo/WorkstationInfo";
import { ROUTE_WORKSTATIONS } from "shared/constants";
import { getBuildingPlan } from "shared/constants/floorsConstants";
import { isEqualData } from "shared/lib";
import {
  getAxisPositions,
  getEmptyWorkstations,
  getFormattedDate,
  getMappedWorkstationList,
  getPathAxisPositions,
  getWorkstationMapItemSvgProps,
  getWorkstationStatus
} from "shared/lib/getters";

import WorkstationInfoPage from "../WorkstationInfoPage/WorkstationInfoPage";
import WorkstationPage from "../WorkstationPage/WorkstationPage";

type Props = {
  path: string;
  showHeader: boolean;
};

const getMapWidth = (width?: number, offsetLeft: number = 0) => {
  if (!width) return;

  const maxRight = offsetLeft + width;

  if (width > 1850) {
    return {
      xCoord: maxRight / 16,
      stageScale: 0.7
    };
  }

  if (width > 1536) {
    return {
      xCoord: maxRight / 20,
      stageScale: 0.5
    };
  }

  if (width > 1150) {
    return {
      xCoord: maxRight / 20
    };
  }
};

const mapStateToProps = (state: any) => ({
  token: selectAccessToken(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  selectedFloors: selectFloorsFilter(state),
  userList: selectUserManagementList(state),
  companyName: selectCompanyName(state),
  selectedSite: selectCompanySite(state)
});

const WorkstationMapView: FC<Props> = ({ path, showHeader = true }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const mapRef = useRef<HTMLDivElement | null>(null);
  const stageAttributes = getMapWidth(mapRef?.current?.offsetWidth, mapRef?.current?.offsetLeft);

  const { token, site, building, selectedFloors, userList, companyName, selectedSite } = useSelector(mapStateToProps);

  const [svgRects, setSvgRects] = useState<any[]>([]);
  const [svgPaths, setSvgPaths] = useState<any[]>([]);

  const [showSidebar, setShowSidebar] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [workstationsMapList, setWorkstationMapList] = useState<any[]>([]);
  const [notAssignedRoomsList, setNotAssignedRoomsList] = useState<any[]>([]);
  const [buildingPlan, setBuildingPlan] = useState<any>();
  const [showAddSidebar, setShowAddSidebar] = useState(false);
  const [roomName, setRoomName] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [workstationId, setWorkstationId] = useState<string>("");

  const { data: mapViewData, isLoading: isMapViewLoading } = useGetMapViewWorkstationsQuery(
    { site, building, floor: selectedFloors[0] },
    { skip: !site || !selectedFloors?.length }
  );
  const { data: workstationData, isLoading: isWorkstationLoading } = useWorkstationsByBuildingsQuery(
    { site, building, date: getFormattedDate(new Date(), "yyyy-MM-dd") },
    { skip: !token || !site || !building }
  );

  const handleGetRooms = useCallback(
    (mapData: any, workstations: any) => {
      if (!mapData?.rooms || !workstations || !building) return;

      const mappedList = getMappedWorkstationList(workstations[building], userList);
      const rooms = { ...mapData.rooms };

      for (const key in mapData.rooms) {
        const room = mapData.rooms[key];
        const updatedRoom = room?.map((seat: any) => {
          const { permanentOwner, assignedEmployee, available } =
            mappedList?.find((i: any) => seat.workstationId === i.id) || {};

          return {
            ...seat,
            status: available ? "available" : seat?.status,
            permanentOwner,
            assignedEmployee
          };
        });

        rooms[key] = updatedRoom;
      }

      dispatch(setWorkstationsByBuildings(mappedList));

      return { rooms };
    },
    [building, userList]
  );

  const handleChangeView = () => {
    history.replace(`/${ROUTE_WORKSTATIONS}`);
  };

  const onRectElementSelected = (data: any) => {
    if (isEqual(svgRects, data)) return;

    setSvgRects(data);
  };

  const onPathElementSelected = (data: any) => {
    if (isEqual(svgPaths, data)) return;

    setSvgPaths(data);
  };

  const onEmptyRoomClick = (room: any) => {
    setRoomName(room);
    setIsEdit(false);
    setShowAddSidebar(true);
  };

  const onWorkstationClick = (id: string) => {
    setWorkstationId(id);
    setShowInfoSidebar(true);
  };

  useEffect(() => {
    if (!selectedFloors?.length || !building || !selectedSite || !companyName) return;

    const plans = getBuildingPlan(companyName, selectedSite, building);

    setBuildingPlan(plans?.find(plan => plan?.floor === selectedFloors[0]));
  }, [selectedFloors, building, selectedSite, companyName]);

  useDeepCompareEffect(() => {
    if (!mapViewData || !workstationData || isMapViewLoading || isWorkstationLoading) return;

    const roomsData = handleGetRooms(mapViewData, workstationData);

    if (!roomsData?.rooms) return;

    const seats =
      svgRects?.flatMap(element => {
        const x = element?.x?.baseVal?.valueAsString;
        const y = element?.y?.baseVal?.valueAsString;
        const transform = element.attributes?.transform?.value;
        const height = element?.height?.baseVal?.valueAsString;
        const width = element?.width?.baseVal?.valueAsString;
        const roomName = element.attributes?.room_name?.value;

        const workstations =
          Object.hasOwn(roomsData?.rooms, parseInt(roomName)) && roomsData?.rooms[parseInt(roomName)];

        return (
          (workstations &&
            workstations?.map((workstation: any, index: number) => {
              const { xCoord, yCoord } = getAxisPositions({
                x: parseInt(x),
                y: parseInt(y),
                transform,
                height: parseInt(height),
                width: parseInt(width),
                index: index + 1
              });

              return {
                x: xCoord,
                y: yCoord,
                id: workstation?.workstationId,
                name: workstation?.workstationName,
                status: workstation?.status,
                owner: workstation?.permanentOwner,
                assigned: workstation?.assignedEmployee,
                ...getWorkstationMapItemSvgProps(workstation.status)
              };
            })) ||
          []
        );
      }) || [];

    const rooms =
      svgPaths?.flatMap(element => {
        const roomName = element.attributes?.room_name?.value;
        const d = element.attributes?.d.nodeValue;

        const workstations =
          Object.hasOwn(roomsData?.rooms, parseInt(roomName)) && roomsData?.rooms[parseInt(roomName)];

        return (
          (workstations &&
            workstations?.map((workstation: any, index: number) => {
              const { xCoord, yCoord } = getPathAxisPositions({ d, index: index + 1 });
              const svgProps = getWorkstationMapItemSvgProps(workstation.status);

              return {
                x: xCoord,
                y: yCoord,
                id: workstation?.workstationId,
                name: workstation?.workstationName,
                status: workstation?.status,
                owner: workstation?.permanentOwner,
                assigned: workstation?.assignedEmployee,
                ...svgProps
              };
            })) ||
          []
        );
      }) || [];

    const emptyRect = getEmptyWorkstations(svgRects || [], roomsData);
    const emptyPath = getEmptyWorkstations(svgPaths || [], roomsData);

    console.log({ emptyRect, emptyPath });

    setNotAssignedRoomsList([...emptyRect, ...emptyPath]);
    setWorkstationMapList([...seats, ...rooms]);
  }, [svgRects, svgPaths, mapViewData, workstationData, isMapViewLoading, isWorkstationLoading, handleGetRooms]);

  return (
    <Box width="100%" ref={mapRef}>
      {showHeader ? (
        <Typography variant="h3" fontWeight="700">
          Workstations
        </Typography>
      ) : null}

      <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
        <Box pt={2}>
          <Grid container display="flex" alignItems="flex-start" spacing={2} px={4} pb={2}>
            <Grid item>
              <SiteAndBuilding onClick={() => setShowSidebar(true)} />
            </Grid>
            <Grid item>
              <FloorSelector isMultiple={false} />
            </Grid>
            <Grid item sx={{ marginLeft: "auto" }}>
              <FormControl>
                <RadioGroup row value="map" onChange={handleChangeView}>
                  <FormControlLabel control={<Radio color="success" />} label="List" value="list" />
                  <FormControlLabel control={<Radio color="success" />} label="Map" value="map" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />

          {!isMapViewLoading ? (
            <Box
              px={4}
              position="relative"
              overflow="hidden"
              sx={{ height: showHeader ? "calc(100vh - 270px)" : "440px" }}
            >
              {buildingPlan?.plan ? (
                <>
                  <BoxContainer>
                    <WorkstationInfo />
                  </BoxContainer>
                  <Box>
                    <MainStage
                      workstationsMapList={workstationsMapList}
                      notAssignedRoomsList={notAssignedRoomsList}
                      onEmptyRoomClick={onEmptyRoomClick}
                      onWorkstationClick={onWorkstationClick}
                      buildingPlan={buildingPlan.plan}
                      xCoord={stageAttributes?.xCoord}
                      stageScale={stageAttributes?.stageScale}
                    />

                    <Box display="none">
                      <SvgLoader id="svgPlan" path={buildingPlan.plan} style={{ width: "100%", height: "auto" }}>
                        <SvgProxy selector="rect" onElementSelected={onRectElementSelected} />
                        <SvgProxy selector="path" onElementSelected={onPathElementSelected} />
                      </SvgLoader>
                      <Box
                        position={"absolute"}
                        sx={{ width: "15px", height: "15px", borderRadius: "50%", background: "blue" }}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box display="flex" flex={1} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                  <Typography variant="h4">Map not available for this floor</Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box height="calc(100vh - 270px)" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Paper>

      <SiteAndBuildingPage isDrawer isOpen={showSidebar} setIsOpen={setShowSidebar} />
      <FilterWorkstationSidebar isDrawer isOpen={showFilterSidebar} setIsOpen={setShowFilterSidebar} />
      {showAddSidebar && (
        <WorkstationPage
          history={history}
          isOpen={showAddSidebar}
          setIsOpen={setShowAddSidebar}
          isEdit={isEdit}
          roomName={roomName}
        />
      )}
      {showInfoSidebar && (
        <WorkstationInfoPage
          history={history}
          isOpen={showInfoSidebar}
          setIsOpen={setShowInfoSidebar}
          workstationId={workstationId}
          isMapView={true}
        />
      )}
    </Box>
  );
};

const BoxContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "32px",
  top: theme.spacing(2),
  background: "#fff",
  borderRadius: theme.spacing(1),
  width: "296px",
  zIndex: 20,
  boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.35)"
}));

export default WorkstationMapView;
