import { format } from "date-fns";
import { v4 } from "uuid";

import AutomationIcon from "shared/assets/icons/automation.svg";
import DualMonitorIcon from "shared/assets/icons/dual-monitor.svg";
import ErgonomicSeatIcon from "shared/assets/icons/ergonomic-seat.svg";
import HotDeskIcon from "shared/assets/icons/hotDesk.svg";
import LandLineIcon from "shared/assets/icons/land-line.svg";
import LargeMonitorIcon from "shared/assets/icons/large-monitor.svg";
import MacIcon from "shared/assets/icons/mac.svg";
import PcIcon from "shared/assets/icons/pc.svg";
import PresentationScreenIcon from "shared/assets/icons/presentation.svg";
import PrinterIcon from "shared/assets/icons/printer.svg";
import UsbIcon from "shared/assets/icons/usb.svg";
import WheelchairIcon from "shared/assets/icons/wheelchair.svg";
import { DAY_TYPES, navigationMap } from "shared/constants";

import { isEmptyObject, isValidAndNotEmptyString, isValidArray, isValidDate, isValidNumber } from "./validation";

export const getCamelizedString = string => {
  if (isValidAndNotEmptyString(string)) {
    return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr?.toUpperCase());
  }
  return "";
};

export const getFilteredList = (list, site, building, floors, departments) => {
  let result = list;

  if (isValidAndNotEmptyString(site) && isValidAndNotEmptyString(building)) {
    result = result?.filter(item => {
      if (item?.site && item?.building) {
        return item.site === site && item.building === building;
      }
      return item;
    });
  }

  if (isValidArray(departments) && departments.length) {
    result = result?.filter(item => {
      if (item?.department) {
        return departments.includes(item.department.toString());
      }
      return item;
    });
  }

  if (isValidArray(floors) && floors.length) {
    result = result?.filter(item => {
      if (item?.floor) {
        return floors.includes(item.floor);
      }
      return item;
    });
  }

  return result;
};

export const getUserManagementFilteredList = (list, site, building, floors, sidebarFilters) => {
  let result = getFilteredList(list, site, building, floors);

  if (!isEmptyObject(sidebarFilters)) {
    const { department, status, officeDays, title } = sidebarFilters;
    if (department?.length) {
      result = result?.filter(user => department.includes(user.department[0]));
    }

    if (status?.length) {
      status.forEach(item => item.toLowerCase());
      let updatedArray = status;

      if (status.some(item => item === "other")) {
        updatedArray = status?.filter(item => item !== "other");
        updatedArray.push("sick", "dayOff", "travel");
      }

      result = result?.filter(user => {
        return updatedArray.includes(getCamelizedString(user.status));
      });
    }

    if (officeDays?.length) {
      result = result?.filter(user => officeDays.includes(`${user.officeDays}`));
    }

    if (title?.length) {
      result = result?.filter(user => title.includes(user.title));
    }
  }

  return result;
};

export const getWorkstationFilteredList = (list, site, building, floors, sidebarFilters) => {
  let result = getFilteredList(list, site, building, floors);

  if (!isEmptyObject(sidebarFilters)) {
    const { roomName } = sidebarFilters;

    if (isValidAndNotEmptyString(roomName)) {
      result = result?.filter(item => item.roomName.includes(roomName));
    }
  }

  return result;
};

export const getFiltersCount = filters => {
  return Object.values(filters)?.filter(item => !!item && item?.length).length;
};

export const getDataEntityItemById = (entityList, itemId) => {
  if (!entityList) return null;
  return entityList.find(({ id }) => id === itemId);
};

export const getFieldName = (label, isRequired) => {
  if (isValidAndNotEmptyString(label)) {
    if (isRequired) {
      return `${label}*`;
    }
    return label;
  }
  return "";
};

export const getFormattedDate = (date, dateFormat) => {
  if (isValidDate(date)) {
    return format(new Date(date), dateFormat);
  }

  return "";
};

export const getDayType = type => {
  const value = type?.toLowerCase();

  switch (value) {
    case DAY_TYPES.home:
      return "Home";
    case DAY_TYPES.off:
      return "Day off";
    case DAY_TYPES.sick:
      return "Sick";
    case DAY_TYPES.travel:
      return "Travel";
    case DAY_TYPES.office:
      return "Office";
    case DAY_TYPES.nonWorking:
    default:
      return "Non working";
  }
};

export const getUserInitials = (firstName, lastName) => {
  let initials = "";
  if (isValidAndNotEmptyString(firstName)) {
    initials += firstName.charAt(0)?.toUpperCase();
  }

  if (isValidAndNotEmptyString(lastName)) {
    initials += lastName.charAt(0)?.toUpperCase();
  }

  return initials;
};

export const getUserInfoById = (userList, userId) => {
  if (isValidNumber(userId) && isValidArray(userList)) {
    return userList.find(({ id }) => id === userId);
  }

  return [];
};

export const getOptions = list => {
  if (isValidArray(list) && list.length) {
    return list.map(item => {
      return { option: item.toString(), value: item };
    });
  }

  return [];
};

export const getFullName = (firstName, lastName) => {
  if (!firstName && !lastName) return "";

  return `${firstName || ""} ${lastName || ""}`.trim();
};

export const getScoreColor = (score, isSelected = false) => {
  if (score > 9) {
    return "#108000";
  }

  if (score < 8 && score > 6) {
    return "#FFAD00";
  }

  if (isSelected) {
    return "#FFF";
  }

  return "#000";
};

export const getAverageValue = ctx => {
  const values = ctx.chart.data.datasets[0].data;
  return values.reduce((a, b) => a + b, 0) / values.length;
};

export const minValue = ctx => {
  const values = ctx.chart.data.datasets[0].data;
  return Math.min(...values);
};

export const minIndex = ctx => {
  const max = minValue(ctx);
  const dataset = ctx.chart.data.datasets[0];
  return dataset.data.indexOf(max);
};

export const minLabel = ctx => {
  return ctx.chart.data.labels[minIndex(ctx)];
};

export const getMappedResponse = list => {
  if (isValidArray(list) && list.length) {
    return list.map(item => ({ ...item, id: v4() }));
  }

  return [];
};

export const getDateText = date => {
  const month = format(new Date(date), "LLL");
  const day = format(new Date(date), "d");

  return `${month} ${day}`;
};

export const getPluralizedString = (count, noun, suffix = "s") => {
  if (count) {
    return `${count} ${noun}${count !== 1 ? suffix : ""}`;
  } else {
    return "";
  }
};

export const getNumberWithOrdinal = n => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getFeatureInformation = featureName => {
  let name = "";
  let icon = null;

  if (featureName) {
    switch (featureName) {
      case "dockingStation": {
        name = "Docking station";
        icon = UsbIcon;
        break;
      }
      case "largeMonitor": {
        name = "Large monitor";
        icon = LargeMonitorIcon;
        break;
      }
      case "wheelchair": {
        name = "Wheelchair accessible";
        icon = WheelchairIcon;
        break;
      }
      case "dualMonitor": {
        name = "Dual monitor";
        icon = DualMonitorIcon;
        break;
      }
      case "mac": {
        name = "Mac";
        icon = MacIcon;
        break;
      }
      case "landLine": {
        name = "Land line";
        icon = LandLineIcon;
        break;
      }
      case "presentationScreen": {
        name = "Presentation screen";
        icon = PresentationScreenIcon;
        break;
      }
      case "printer": {
        name = "Printer";
        icon = PrinterIcon;
        break;
      }
      case "ergonomicChair": {
        name = "Ergonomic chair";
        icon = ErgonomicSeatIcon;
        break;
      }
      case "pc": {
        name = "PC";
        icon = PcIcon;
        break;
      }
      case "hotDesk": {
        name = "Can be booked from mobile";
        icon = HotDeskIcon;
        break;
      }
      case "availableForAutoSeating": {
        name = "Auto assigned";
        icon = AutomationIcon;
        break;
      }
      default: {
        name = featureName;
        icon = null;
      }
    }
  }

  return { name, icon };
};

export const getIsPrivateRoom = roomType => {
  if (roomType) {
    if (roomType.startsWith("PRIVATE")) {
      return "Yes";
    } else {
      return "No";
    }
  } else {
    return "No";
  }
};

export const getRoomTypeTitle = (roomType, isHumanReadable = false) => {
  let title = "";

  if (isHumanReadable) {
    switch (roomType) {
      case "PRIVATE_ASSOCIATE": {
        title = "Private, associate";
        break;
      }
      case "PRIVATE_PARTNER": {
        title = "Private, partner";
        break;
      }
      case "SHARED_ASSOCIATE": {
        title = "Shared, associate";
        break;
      }
      case "SHARED_INTERN": {
        title = "Shared, intern";
        break;
      }
      case "SHARED_PRE_INTERN": {
        title = "Shared, pre-Intern";
        break;
      }
    }
  } else {
    switch (roomType) {
      case "Private, associate": {
        title = "PRIVATE_ASSOCIATE";
        break;
      }
      case "Private, partner": {
        title = "PRIVATE_PARTNER";
        break;
      }
      case "Shared, associate": {
        title = "SHARED_ASSOCIATE";
        break;
      }
      case "Shared, intern": {
        title = "SHARED_INTERN";
        break;
      }
      case "Shared, pre-Intern": {
        title = "SHARED_PRE_INTERN";
        break;
      }
    }
  }

  return title;
};

export const getMappedWorkstationList = (workstationList, userList) => {
  if (!workstationList?.length) return [];

  const mappedList = workstationList?.map(workstation => ({
    ...workstation,
    id: workstation.workstationId,
    seatFeatures: workstation?.seatFeatures?.seatFeatures || [],
    privateRoom: getIsPrivateRoom(workstation.roomType),
    roomType: getRoomTypeTitle(workstation.roomType, true)
  }));

  const result = mappedList?.map(workstation => {
    const permanentOwner = userList?.find(user => workstation.ownerId === user.id);
    const assignedEmployee = userList?.find(user => workstation.assignedEmployeeId === user.id);
    let mappedWorkstation = { ...workstation, permanentOwner: "", assignedEmployee: "" };

    if (permanentOwner) {
      const { firstName, lastName, value } = permanentOwner;

      mappedWorkstation = {
        ...mappedWorkstation,
        permanentOwner: firstName && lastName ? getFullName(firstName, lastName) : value || ""
      };
    }

    if (assignedEmployee) {
      const { firstName, lastName, value } = assignedEmployee;

      mappedWorkstation = {
        ...mappedWorkstation,
        assignedEmployee: firstName && lastName ? getFullName(firstName, lastName) : value || ""
      };
    }

    return mappedWorkstation;
  });
  return result;
};

export const getAxisPositions = ({ x, y, transform, height, width, index }) => {
  let xCoord = x;
  let yCoord = y;

  if (isValidAndNotEmptyString(transform)) {
    const transformRegex = /\((-?[0-9]+)/;
    const match = transform.match(transformRegex);
    const rotateValue = match[1];

    if (rotateValue === "180") {
      xCoord = index <= 3 ? x - width - 20 + index * 60 : x - width * 2 + index * 60;
      yCoord = index <= 3 ? y - height + 35 : y - height + 90;
    }

    if (rotateValue === "-90") {
      xCoord = index <= 3 ? x - 20 + index * 60 : x - width / 1.5 + index * 60;
      yCoord = index <= 3 ? y - height + 70 : y - height + 110;
    }

    if (rotateValue === "90") {
      xCoord = index <= 3 ? x - height - 20 + index * 65 : x - height * 1.8 + index * 65;
      yCoord = index <= 3 ? y + 50 : y + 105;
    }

    if (rotateValue === "135") {
      xCoord = index <= 3 ? x - height - 40 + index * 40 : x - height * 2 + index * 60;
      yCoord = index <= 3 ? y + width - 280 + index * 40 : y + width + 330;
    }

    if (rotateValue === "45") {
      xCoord = index <= 3 ? x - height + 250 - index * 50 : x - height - 40 + index * 40;
      yCoord = index <= 3 ? x - width - 300 + index * 40 : x - height - 100;
    }
  } else {
    xCoord = index <= 3 ? x - 25 + index * 60 : x - width + 13 + index * 60;
    yCoord = index <= 3 ? y + 35 : y + index * 2 + 80;
  }

  return { xCoord, yCoord };
};

export const getPathAxisPositions = ({ d, index }) => {
  let xCoord = null;
  let yCoord = null;

  if (d.startsWith("M2452.62")) {
    xCoord = index <= 3 ? 2360 + index * 60 : 2180 + index * 60;
    yCoord = index <= 3 ? 145 : 200;
  }
  if (d.startsWith("M2741.18")) {
    xCoord = index <= 3 ? 2430 + index * 60 : 2250 + index * 60;
    yCoord = index <= 3 ? 1320 : 1370;
  }
  if (d.startsWith("M498")) {
    xCoord = index <= 3 ? 190 + index * 60 : 10 + index * 60;
    yCoord = index <= 3 ? 2320 : 2380;
  }
  if (d.startsWith("M1374")) {
    xCoord = index <= 3 ? 1390 + index * 60 : 1170 + index * 60;
    yCoord = index <= 3 ? 2270 + index * 50 : 2160 + index * 50;
  }
  if (d.startsWith("M1374.41 2641.2V2412.27L1490.47")) {
    xCoord = index <= 3 ? 1430 + index * 60 : 1170 + index * 60;
    yCoord = index <= 3 ? 2300 + index * 50 : 2160 + index * 50;
  }
  if (d.startsWith("M436.879")) {
    xCoord = index <= 3 ? 315 + index * 60 : 90 + index * 60;
    yCoord = index <= 3 ? 150 : 200;
  }
  if (d.startsWith("M210")) {
    xCoord = index <= 3 ? 200 + index * 60 : 200 + index * 60;
    yCoord = index <= 3 ? 2241 : 2141;
  }
  if (d.startsWith("M567.875")) {
    xCoord = index <= 3 ? 440 + index * 60 : 440 + index * 60;
    yCoord = index <= 3 ? 2415 : 2330;
  }
  if (d.startsWith("M2384.5")) {
    xCoord = 2360 + index * 60;
    yCoord = 150;
  }
  if (d.startsWith("M211")) {
    xCoord = index <= 2 ? 295 + index * 60 : 135 + index * 55;
    yCoord = index <= 2 ? 150 : 200;
  }

  return { xCoord, yCoord };
};

export const getCurrentWorkstationsStatus = workstations => {
  const counts = {
    available: 0,
    arrived: 0,
    reserved: 0,
    vacant: 0
  };

  if (isValidArray(workstations) && workstations.length) {
    workstations.forEach(workstation => {
      if (workstation.assignedEmployeeId) {
        if (workstation.arrived) {
          counts.arrived += 1;
        } else {
          counts.reserved += 1;
        }
      } else if (workstation.available) {
        counts.available += 1;
      } else {
        if (workstation.available) {
          counts.available += 1;
        } else {
          counts.vacant += 1;
        }
      }
    });
  }

  return counts;
};

export const getMappedWorkstationFilters = filters => {
  if (filters) {
    const result = {
      ...filters,
      ownerId: filters.employeeId,
      neighborhoodName: filters.neighborhoods[0],
      floor: filters.floors[0],
      seatFeatures: filters.features,
      roomType: getRoomTypeTitle(filters.roomTypes[0], true)
    };

    delete result.companyId;
    delete result.features;
    delete result.floors;
    delete result.neighborhoods;
    delete result.employeeId;
    delete result.roomTypes;

    return result;
  }
};

export const getWorkstationStatus = (workstation, defaultStatus = "Vacant") => {
  if (workstation) {
    if (workstation.available) {
      return "Available";
    }

    if (workstation.arrived) {
      return "In use";
    }

    if (workstation.assignedEmployeeId) {
      return "Reserved";
    } else {
      return defaultStatus;
    }
  }

  return "";
};

export const getWorkstationMapItemSvgProps = status => {
  const defaultSvgProps = {
    fill: "#6B6C72",
    strokeColor: "none",
    strokeWidth: 0,
    radius: 20
  };

  switch (status) {
    case "available":
      return {
        ...defaultSvgProps,
        fill: "#fff",
        strokeColor: "#1ED400",
        strokeWidth: 8,
        radius: 18
      };
    case "arrived":
      return {
        ...defaultSvgProps,
        fill: "#108000"
      };
    case "reserved":
      return {
        ...defaultSvgProps,
        fill: "#007399"
      };
    case "vacant":
      return {
        ...defaultSvgProps,
        fill: "#6B6C72"
      };
    default:
      return defaultSvgProps;
  }
};

export const getUsersWithoutWorkstations = usersList => {
  if (isValidArray(usersList) && usersList.length) {
    return usersList;
  }

  return [];
};

export const getCenterCoordinates = ({ x, y, transform, width, height, d }) => {
  let cx;
  let cy;

  if (isValidAndNotEmptyString(transform)) {
    const transformRegex = /\((-?[0-9]+)/;
    const match = transform?.match(transformRegex);
    const rotateValue = match[1];

    if (rotateValue === "180") {
      cx = x - width - 20 * 60;
      cy = y - height + 35;
    }

    if (rotateValue === "-90") {
      cx = x + height / 2;
      cy = y - width / 2;
    }

    if (rotateValue === "90") {
      cx = x - height / 2;
      cy = y + width / 2;
    }

    if (rotateValue === "135") {
      cx = x - width / 1.5;
      cy = y - height + 210;
    }

    if (rotateValue === "45") {
      cx = x + height - 350 / 2;
      cy = y + height - 100 / 2;
    }
  }

  if (d) {
    if (d.startsWith("M2740.5")) {
      cx = 2630;
      cy = 370;
    }
    if (d.startsWith("M2741.18")) {
      cx = 2580;
      cy = 1380;
    }
  }

  if (!isValidAndNotEmptyString(transform) && !d) {
    cx = x + width / 2;
    cy = y + height / 2;
  }

  return { cx, cy };
};

export const getEmptyWorkstations = (svgElements, workstationList) => {
  const result = svgElements
    ?.flatMap(element => {
      const x = element?.x?.baseVal?.valueAsString;
      const y = element?.y?.baseVal?.valueAsString;
      const transform = element.attributes?.transform?.value;
      const height = element?.height?.baseVal?.valueAsString;
      const width = element?.width?.baseVal?.valueAsString;
      const roomName = element.attributes?.room_name?.value;
      const d = element.attributes?.d?.nodeValue || null;

      if (roomName && !Object.hasOwn(workstationList?.rooms, parseInt(roomName))) {
        const { cx, cy } = getCenterCoordinates({
          x: parseInt(x),
          y: parseInt(y),
          transform,
          height: parseInt(height),
          width: parseInt(width),
          d
        });

        return {
          x: cx,
          y: cy,
          roomName
        };
      }

      return;
    })
    .filter(Boolean);

  return result?.length ? result : [];
};

export const getNavigationMap = navigationResources => {
  if (!navigationResources?.length) return [];

  const sortedList = navigationResources
    .map(item => navigationMap[item])
    ?.filter(item => !item?.hide && item?.order)
    ?.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

  return sortedList;
};

export function getBrowserName() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) !== -1) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true) {
    // IF IE > 10
    return "Internet Explorer";
  } else {
    return "unknown";
  }
}

export function getWorkstationsOptions(list) {
  if (!isValidArray(list) || !list?.length) return [];

  return list
    .map(item => {
      const status = getWorkstationStatus(item, "");
      const value = status ? `${item.workstationName} - ${status.toLowerCase()}` : item.workstationName;

      const mappedItem = {
        id: item.workstationId,
        option: item.workstationId,
        value,
        assignedEmployeeId: item.assignedEmployeeId,
        room: item.roomName,
        disabled: status !== "Available",
        floor: item?.floor
      };

      return mappedItem;
    })
    .sort((a, b) => a.value?.localeCompare(b.value));
}

export function getFloorsByBuildingOptions(floorsByBuildingState, building) {
  if (floorsByBuildingState && !isEmptyObject(floorsByBuildingState) && building) {
    const data = floorsByBuildingState[building];

    return data && data?.length
      ? data
          .filter(x => !!x)
          .map(floor => {
            return { option: floor, value: floor };
          })
          .sort((a, b) => a.option - b.option)
      : [];
  }
}
