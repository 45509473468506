import { useMemo, useState } from "react";

export function useToggle() {
  const [enable, setEnable] = useState(false);

  /* ------------------ HANDLERs ------------------ */

  const handlers = useMemo(
    () => ({
      on: () => setEnable(true),
      off: () => setEnable(false),
      toggle: () => setEnable(prev => !prev),
      set: value => setEnable(value)
    }),
    []
  );

  return { ...handlers, enable };
}
